<template>
  <div class="media w100 h100">
    <div class="filter_row">
      <a-tabs v-model:activeKey="activeTabKey" @change="onTabChange">
        <a-tab-pane key="TT" tab="巨量引擎" force-render></a-tab-pane>
        <!--        <a-tab-pane key="GDT" tab="腾讯广告"></a-tab-pane>
                <a-tab-pane key="KS" tab="磁力引擎"></a-tab-pane>
                <a-tab-pane key="QC" tab="巨量千川"></a-tab-pane>-->
      </a-tabs>
      <a-form :model="filterConditions">
        <a-row type="flex">
          <div style="flex: 1">
            <a-row class="media-choice">
              <a-col :span="4">
                <a-form-item label='素材ID' :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
                  <a-input v-model:value="filterConditions.mdaIds" placeholder="请输入素材ID" @press-enter="enterIdSearch"></a-input>
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item label='广告账户' :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
                  <a-input v-model:value="filterConditions.mdaNames" placeholder="请输入广告账户ID/名称" @press-enter="enterIdSearch"></a-input>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label='代理商账户' :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
                  <a-select v-model:value="filterConditions.agentId"  placeholder="请选择代理商帐户" style="width: 100%" :allow-clear="true">
                    <a-select-option :value="accManager.advertiserId" :label="accManager.advertiserId+'-'+accManager.advertiserName " v-for="accManager in managerAccountListTemp" :key="accManager.advertiserId">{{ accManager.advertiserId }}-{{accManager.advertiserName}}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item label='违规分类' :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
                  <a-select v-model:value="filterConditions.msgType" placeholder="请选择" allow-clear style="width: 100%">
                    <a-select-option value="1">素材处理</a-select-option>
                    <a-select-option value="2">计划下线预警</a-select-option>
                    <a-select-option value="3">计划下线</a-select-option>
                    <a-select-option value="4">账户关停</a-select-option>
                    <a-select-option value="5">侵权预警</a-select-option>
                    <a-select-option value="6">侵权封禁</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label='推送时间' :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                  <a-range-picker :placeholder="['开始日期', '结束日期']" v-model:value="defaultRange" :allow-clear="false" separator="到" :format="[dateRangeFormat, dateRangeFormat]" :ranges="dateRanges" :disabled-date="disabledDate" @change="onDateRangeChange" :disabled="tableLoading" />
                </a-form-item>
              </a-col>
            </a-row>
          </div>
          <a-space>
            <a-button type="primary" @click="onSearchBtnClick" :disabled="tableLoading">筛选</a-button>
            <a-button @click="resetFilter" :disabled="tableLoading">重置</a-button>
          </a-space>
        </a-row>
      </a-form>

      <a-drawer width="50%" :title="`${riskPromotionDetail.riskPromotionDetailMsg.msgTypeText}基本信息`" placement="right" :closable="false" v-model:visible="riskPromotionDetail.riskPromotionDetailVisible" class="cusotmer-drawer">
        <a-tabs v-model:activeKey="riskPromotionDetail.activeKey">
          <a-tab-pane key="1" tab="基本信息">
            <a-form :rules="riskPromotionDetail.rules">
              <a-form-item label="素材ID" :label-col="labelCol" :wrapper-col="wrapperCol" required>
                <a-input :disabled="true" v-model:value="riskPromotionDetail.riskPromotionDetailMsg.materialIds" />
              </a-form-item>
              <a-form-item label="计划ID" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-input :disabled="true" v-model:value="riskPromotionDetail.riskPromotionDetailMsg.planId" />
              </a-form-item>
              <a-form-item label="计划名称" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-input :disabled="true" v-model:value="riskPromotionDetail.riskPromotionDetailMsg.planName" />
              </a-form-item>
              <a-form-item label="广告主ID" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-input :disabled="true" v-model:value="riskPromotionDetail.riskPromotionDetailMsg.advId" />
              </a-form-item>
              <a-form-item label="广告主名称" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-input :disabled="true" v-model:value="riskPromotionDetail.riskPromotionDetailMsg.advName" />
              </a-form-item>
              <a-form-item label="客户名称" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-input :disabled="true" v-model:value="riskPromotionDetail.riskPromotionDetailMsg.customerName" />
              </a-form-item>
              <a-form-item label="代理商账户名称" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-input :disabled="true" v-model:value="riskPromotionDetail.riskPromotionDetailMsg.agentName" />
              </a-form-item>
              <a-form-item label="优化师负责人" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-input :disabled="true" v-model:value="riskPromotionDetail.riskPromotionDetailMsg.optimizerName" />
              </a-form-item>
              <a-form-item label="优化师协作者" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-input :disabled="true" v-model:value="riskPromotionDetail.riskPromotionDetailMsg.collaboratorNames" />
              </a-form-item>
              <a-form-item label="违规内容" :label-col="labelCol" :wrapper-col="wrapperCol">
                <html v-html='riskPromotionDetail.riskPromotionDetailMsg.illegalMaterialContent'></html>
              </a-form-item>
            </a-form>
          </a-tab-pane>
        </a-tabs>
      </a-drawer>

    </div>
    <div class="table_wrapper">
      <a-space :size="10" class="table_operation">
        <!--        <a-button :disabled="batchBindPersonDisabled" @click="operation({}, 'total')" type="primary">总计</a-button>-->
      </a-space>
      <!--      <a-table class="media_account_table w100" size="small" :columns="violationInformationTableColumns" bordered row-key="advertiserId" :data-source="violationInfoTableData" :loading="tableLoading" :pagination="tablePagination" :scroll="{ y: 200 }" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" @change="onTableChange" :custom-row="record => customClickRow(record)">-->
      <a-table :columns="violationInformationTableColumns" :data-source="violationInfoTableData" :scroll="{ x: 1500, y: 620 }" class="w100" size="small" bordered :loading="tableLoading" :pagination="tablePagination">
        <template #msgTypeText="{ record }">
          <a @click.stop="onViewDetailsClick(record)">{{ record.msgTypeText}}</a>
        </template>
      </a-table>
    </div>
    <total-detail :row-data="rowData" ref="totalDetail"></total-detail>
    <row-detail :row-data="rowData" ref="rowDetail"></row-detail>
  </div>
</template>

<script>
import moment, { Moment } from 'moment';
import locale from 'ant-design-vue/lib/locale-provider/zh_CN';
import { message, Modal } from 'ant-design-vue';
import {
  getManagerAccountListData,
  getMediaAccountListData,
} from '@/api/mediaAccount/mediaAccount.js';
import { Empty } from 'ant-design-vue';
import TotalDetail from './TotalDetail.vue';
import RowDetail from './RowDetail.vue';
import { mapState } from 'vuex';
import { ref } from 'vue';
import violationInformation from '@/views/violationInformation/index.vue';
import { getViolationListData } from '@/api/violationInformation/violationInformation';

export default {
  components: { TotalDetail, RowDetail,},
  data() {
    // 从推广管理账户批量分配账号带过来的数据
    const activeTabKey = this.$route?.params?.channel || 'TT';
    const mdaIds = this.$route?.params?.ids?.join(' ') || '';
    // const projectCode= this.$route?.params?.productId || '';
    // 从推广管理账户未绑定产品账号名称带过来的数据
    const projectDistributionState = this.$route?.params?.projectDistributionState || null;
    const dateRanges = {
      昨天: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      最近三天: [moment().subtract(2, 'days'), moment()],
      本周: [moment().startOf('week'), moment.min(moment(), moment().endOf('week'))],
      最近七天: [moment().subtract(6, 'days'), moment()],
      本月: [moment().startOf('month'), moment.min(moment(), moment().endOf('month'))],
      最近三十天: [moment().subtract(29, 'days'), moment()],
      最近九十天: [moment().subtract(89, 'days'), moment()],
    };
    const disabledDate = current => {
      return current && current > moment().add(1, 'days').startOf('day');
    };
    // rangepicker默认选中日期
    const defaultRange = ref([moment(), moment()]);
    return {
      moment,
      dateRangeFormat: 'YYYY-MM-DD',
      dateRanges,
      disabledDate,
      defaultRange,
      activeTabKey: activeTabKey,
      locale,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      distributionOperatorDisabled: true, //会话框运营人员禁用
      tableLoading: false,
      selectedRowKeys: [], // 选中的行的id
      selectedRows: [],
      tablePagination: {
        limit: '30',
        page: '1',
        pageSize: 30,
        showTotal: total => `总计${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ['30', '50', '100'],
      },
      violationInformationTableColumns: [
        {
          title: '计划ID',
          width: 140,
          dataIndex: 'planId',
          key: 'planId',
          fixed: 'left',
        },
        {
          title: '计划名称',
          width: 150,
          dataIndex: 'planName',
          key: 'planName',
          fixed: 'left',
        },
        {
          title: '违规分类',
          width: 100,
          dataIndex: 'msgTypeText',
          key: 'msgTypeText',
          fixed: 'left',
          slots:{
            customRender: 'msgTypeText',
          },
        },
        {
          title: '广告主ID',
          width: 100,
          key: 'advId',
          dataIndex: 'advId',
        },
        {
          title: '广告主名称',
          width: 100,
          key: 'advName',
          dataIndex: 'advName',
        },
        {
          title: '素材ID',
          width: 150,
          key: 'materialIds',
          dataIndex: 'materialIds',
        },
        {
          title: '代理商ID',
          width: 150,
          key: 'agentId',
          dataIndex: 'agentId',
        },
        {
          title: '代理商名称',
          width: 150,
          key: 'agentName',
          dataIndex: 'agentName',
        },
        {
          title: '客户名称',
          width: 150,
          key: 'customerName',
          dataIndex: 'customerName',
        },
        {
          title: '推送时间',
          width: 150,
          key: 'sendTime',
          dataIndex: 'sendTime',
        },
        {
          title: '违规位置',
          width: 150,
          key: 'illegalPosition',
          dataIndex: 'illegalPosition',
        },
        {
          title: '违规内容',
          width: 150,
          key: 'illegalMaterialContent1',
          dataIndex: 'illegalMaterialContent1',
        },
        {
          title: '违规原因',
          width: 150,
          key: 'rejectReason1',
          dataIndex: 'rejectReason1',
        },
        {
          title: '优化师负责人',
          width: 150,
          key: 'optimizerName',
          dataIndex: 'optimizerName',
        },
        {
          title: '优化师协作者',
          width: 150,
          key: 'collaboratorNames',
          dataIndex: 'collaboratorNames',
        },
      ],
      violationInfoTableData: [],
      managerAccountListTemp:[],
      filterConditions: {
        advId: '',
        advName: '',
        //素材清理
        agentId: '',
        pushStartDate: null,
        pushEndDate:null,//账户
        msgType:null,
      },
      labelCol: { sm: { span: 8 }, md: { span: 8 }, lg: { span: 8 }, xl: { span: 8 }, xxl: { span: 6 } },
      wrapperCol: { sm: { span: 14 }, md: { span: 14 }, lg: { span: 14 }, xl: { span: 14 }, xxl: { span: 16 } },
      rowData: {}, //当前行的数据
      accountData: [], //传给弹框的选中行数据
      filterConditionMap: {},

      riskPromotionDetail: {
        companyCode: '', //查看公司的code码
        riskPromotionDetailVisible: false,
        customerName: '',
        activeKey: '1',
        collapseActiveKey: '1',
        channelType: '', //客户的媒体渠道
        riskPromotionDetailMsg: {}, //客户详情信息(基本信息)

        mdaccountIds: '', //开户主体的id
        mdaccountList: [], //开户主体数据
        mdaccountTotal: Number, //开户主体数量
        mdaccountPagination: {
          page: '1',
          limit: 10,
          pageSize: 10,
          showTotal: total => `总计${total}条数据`,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50'],
        },

        customerMediaMessage: [], //客户媒体信息(媒体 ==> 表格)
        customerMediaTotal: Number, //客户媒体信息数量
        mediaPagination: {
          //客户媒体信息分页器
          page: '1',
          limit: 10,
          pageSize: 10,
          showTotal: total => `总计${total}条数据`,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50'],
        },

        customerProjectMessage: [], //客户产品信息(产品 ==> 表格)
        customerProductTotal: Number, //客户产品数量
        productPagination: {
          //客户产品分页器
          page: '1',
          limit: 10,
          pageSize: 10,
          showTotal: total => `总计${total}条数据`,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50'],
        },

        costomerMediaAccount: [], //客户媒体账号信息(媒体账号 ==> 表格)
        customerMediaAccountTotal: Number, //客户媒体账号数量
        showMediaAccountLoading: false,
        mediaAccountPagination: {
          //客户媒体账号分页器
          page: '1',
          limit: 10,
          pageSize: 10,
          showTotal: total => `总计${total}条数据`,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50'],
        },

        financeMessage: [], //客户的财务信息
        financeMessageTotal: Number, //客户财务信息数量
        rules: {
          name: [
            {
              required: true,
              trigger: 'blur',
            },
          ],
        },
        // 查看客户开户主体表单
        subjectColumns: [
          {
            title: '开户主体',
            dataIndex: 'invoiceName',
            key: 'invoiceName',
            slots: {
              customRender: 'invoiceName',
            },
          },
          {
            title: '累计消耗',
            dataIndex: 'cost',
            key: 'cost',
            slots: {
              customRender: 'cost',
            },
          },
          {
            title: '账户数量',
            dataIndex: 'mdaccountCount',
            key: 'mdaccountCount',
            slots: {
              customRender: 'mdaccountCount',
            },
          },
          {
            title: '当前余额',
            dataIndex: 'balance',
            key: 'balance',
            slots: {
              customRender: 'balance',
            },
          },
        ],
        // 查看客户媒体表单
        mediaColumns: [
          {
            title: '媒体',
            dataIndex: 'channelType',
            key: 'channelType',
            slots: {
              customRender: 'channelType',
            },
          },
          {
            title: '销售',
            dataIndex: 'salerName',
            key: 'salerName',
            slots: {
              customRender: 'salerName',
            },
          },

          {
            title: '累计消耗',
            dataIndex: 'cost',
            key: 'cost',
            slots: {
              customRender: 'cost',
            },
          },
          {
            title: '账户数量',
            dataIndex: 'mdaccountCount',
            key: 'mdaccountCount',
            slots: {
              customRender: 'mdaccountCount',
            },
          },
        ],
        //  查看客户产品表单
        productColumns: [
          {
            title: '产品ID',
            dataIndex: 'projectCode',
            key: 'projectCode',
            slots: {
              customRender: 'projectCode',
            },
          },
          {
            title: '产品名称',
            dataIndex: 'projectName',
            key: 'projectName',
            slots: {
              customRender: 'projectName',
            },
          },

          {
            title: '累计消耗',
            dataIndex: 'cost',
            key: 'cost',
            slots: {
              customRender: 'cost',
            },
          },
        ],

        // 查看客户媒体账号
        accountColumns: [
          {
            title: '媒体账号ID',
            dataIndex: 'mdaccountId',
            key: 'mdaccountId',
            width: 180,
            fixed: 'left',
            slots: {
              customRender: 'mdaccountId',
            },
          },
          {
            title: '产品ID',
            dataIndex: 'projectCode',
            key: 'projectCode',
            width: 180,
            fixed: 'left',
            slots: {
              customRender: 'projectCode',
            },
          },

          {
            title: '产品名称',
            dataIndex: 'projectName',
            key: 'projectName',
            slots: {
              customRender: 'projectName',
            },
          },
          {
            title: '媒体',
            dataIndex: 'channelType',
            key: 'channelType',
            slots: {
              customRender: 'channelType',
            },
          },
          {
            title: '开户主体',
            dataIndex: 'invoiceName',
            key: 'invoiceName',
            slots: {
              customRender: 'invoiceName',
            },
          },
          {
            title: '累计消耗',
            dataIndex: 'cost',
            key: 'cost',
            slots: {
              customRender: 'cost',
            },
          },
          {
            title: '当前消耗情况',
            dataIndex: 'name',
            key: 'name',
            slots: {
              customRender: 'name',
            },
          },
        ],
        viewAccountPagination: {
          limit: '10',
          page: '1',
        },
        viewMediaPagination: {
          limit: '10',
          page: '1',
        },
      },
    };
  },
  inject: ['$bus'],
  computed: {
    violationInformation() {
      return violationInformation
    },
    periodComputed(){
      return  moment(new Date()).format("YYYY-MM") ;
    },
    ...mapState({ userJobs: state => state.oauth.userInfo.jobs }),
    funcPoints() {
      return localStorage.getItem('funcPoint');
    },
  },
  created() {
    this.getManagerAccountListTemp();
    this.getViolationListData();
  },
  methods: {
    getManagerAccountListTemp(){
      let mediaChannelTemp = this.activeTabKey;
      getManagerAccountListData(mediaChannelTemp).then(res => {
        if (res.code == 0) {
          this.managerAccountListTemp  = res.data;
        }
      });
    },
    onViewDetailsClick(record) {
      this.riskPromotionDetail.companyCode = record.companyCode;
      this.riskPromotionDetail.mdaccountIds = record.mdaccountIds;
      this.riskPromotionDetail.channelType = record.channelType;
      this.riskPromotionDetail.customerName = record.customerOfCompanyName;
      this.riskPromotionDetail.riskPromotionDetailVisible = true;
      this.riskPromotionDetail.riskPromotionDetailMsg = record;
      this.riskPromotionDetail.mdaccountPagination.page = '1';
      this.riskPromotionDetail.mediaPagination.page = '1';
      this.riskPromotionDetail.productPagination.page = '1';
      this.riskPromotionDetail.mediaAccountPagination.page = '1';
    },
    // 开始时间结束时间选择
    onDateRangeChange(val, dateString) {
      this.filterConditions.pushStartDate = dateString[0];
      this.filterConditions.pushEndDate = dateString[1];
      this.tablePagination.page = '1';
      this.getAdvertiserReport();
    },
    enterIdSearch() {
      this.tablePagination.page = '1';
      this.getViolationListData();
    },
    onTabChange() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.resetFilter();
    },
    getViolationListData() {
      if (this.tableLoading === true) {
        return;
      }
      this.tableLoading = true;
      this.filterConditions.advName = this.filterConditions.advName.trim();

      let params = {
        mediaChannel: this.activeTabKey,
        ...this.tablePagination,
        ...this.filterConditions,
      };

      if (this.filterConditions.industryModel == '') {
        delete params.industryModel;
      }

      getViolationListData(params).then(res => {
        if (res.code == 0) {
          this.violationInfoTableData = res.data.list;
          this.tablePagination.current = res.data.currPage;
          this.tablePagination.pageSize = res.data.pageSize;
          this.tablePagination.total = res.data.totalCount;
        }
        this.tableLoading = false;
      });
    },
    // 媒体账号表格
    onTableChange(pagination) {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.tablePagination.page = pagination.current.toString();
      this.tablePagination.limit = pagination.pageSize.toString();
      this.tablePagination.pageSize = pagination.pageSize;
      this.getMediaAccountList();
    },
    onSearchBtnClick() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      if (this.tablePagination.page !== '1') {
        this.tablePagination = {};
        this.tablePagination.limit = '10';
        this.tablePagination.page = '1';
      }
      this.getViolationListData();

    },
    resetFilter() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.tablePagination.page = '1';
      this.filterConditions = {
        operatorId: null, mdaIds: '',
        mdaNames: '',
        selectType: 'account',
        // agentId: 1775646369326147,//代理商主账户
        period: moment(new Date()).format("YYYY-MM") ,//素材清理月份
      },
        this.getViolationListData();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
  },
};
</script>

<style lang="less">
@import url('./index.less');
</style>
<style lang="less" scoped>
.media {
  display: flex;
  flex-direction: column;
  :deep(.filter_row) {
    padding-top: 0;
    padding-bottom: 6px;
    .ant-tabs-top-bar {
      margin-bottom: 8px;
    }
  }
  .table_wrapper {
    display: flex;
    flex-direction: column;
    .table_operation {
      margin-bottom: 10px;
    }
    :deep(.media_account_table) {
      flex-grow: 1;
      .ant-table-scroll .ant-table-body {
        max-height: calc(100vh - 390px) !important;
      }
      .ant-table-pagination {
        margin-bottom: 0 !important;
        margin-top: 12px;
      }
    }
  }
}
</style>