import axios from '@/utils/axios';
const apiUrl = {
    //主页的接口
    getViolationList: '/extractor/tt/violation/list',
    violationUpdate: '/extractor/tt/violation/update', //获取客户分页信息
    getViolationPromotionList: '/extractor/tt/violation/promotion/list',

};

//查询接口
export function getViolationListData(params) {
    return axios.post(apiUrl.getViolationList, params);
}

export function handlerViolationUpdate(params) {
    return axios.get(apiUrl.violationUpdate, params);
}

export function getViolationPromotionList(params) {
    console.log(params)
    return axios.get(apiUrl.getViolationPromotionList+'?materialId='+ params);
}



